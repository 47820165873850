(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/medicamentos/medicamentos.add.tpl.html',
    '<div class="box box-primary"><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-header with-border"><h2 class="box-title"><b>Nuevo Medicamento</b></h2><br><div class="box-body"><div class="row"><div class="col-sm-12" ng-class="{ \'has-error\' : form.medicamentoNombre.$invalid && form.$submitted }"><label class="control-label" for="medicamentoNombre">Nombre</label> <input class="form-control" name="medicamentoNombre" ng-model="medicamento.nombre" minlength="2" maxlength="50" letters-only="" required=""></div></div><div class="row"><div class="col-sm-9" ng-class="{ \'has-error\' : form.medicamentoPresentacion.$invalid && form.$submitted }"><label class="control-label" for="medicamentoPresentacion">Presentacion</label> <input class="form-control" name="medicamentoPresentacion" ng-model="medicamento.presentacion" minlength="2" maxlength="50" letters-only="" required=""></div><div class="col-sm-3"><label class="control-label" for="esMuestraMedica"></label><div class="form-group"><input type="checkbox" name="fancy-checkbox-default" id="fancy-checkbox-default" autocomplete="off" ng-model="medicamento.muestraMedica" style="display: none" checked="true"><div class="btn-group"><label for="fancy-checkbox-default" class="btn btn-default">Muestra Medica</label> <label for="fancy-checkbox-default" class="btn btn-default" style="width: 40px;"><span class="glyphicon glyphicon-ok" ng-show="medicamento.muestraMedica"></span> <span ng-show="!medicamento.muestraMedica">&nbsp</span></label></div></div></div></div></div><div class="box-footer text-right"><button type="submit" class="btn btn-success btn-sm"><i class="fa fa-floppy-o"></i> Guardar</button> <button type="button" class="btn btn-danger btn-sm"><i class="fa fa-times-circle"></i> Cancelar</button></div></div></form></div>');
}]);
})();
