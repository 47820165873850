(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/common/uploader-tpl.tpl.html',
    '<div class="row m-t-10"><div class="col-sm-4"><input type="file" name="archivo" file-input="archivo"></div><div class="col-sm-2"><button type="button" ng-click="uploadFile(true)" class="btn btn-primary btn-xs">Subir archivo</button></div><div class="col-sm-5"><uib-progressbar class="progress-striped active" max="100" value="avance" type="success"><i>{{avance}}%</i></uib-progressbar></div></div><div ui-grid="gridOptionsFiles" ui-grid-pagination="" ui-grid-auto-resize="" class="grid m-t-10" ng-show="gridOptionsFiles.data.length"></div>');
}]);
})();
