(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/autorizaciones/autorizaciones.list.tpl.html',
    '<div class="box box-primary"><div class="box-header with-border"><h2 class="box-title"><b>Solicitudes de cambios pendientes de autorizar</b></h2></div><div class="box-body"><div class="row"><div class="col-sm-12"><div class="table-responsive"><table ng-table="vm.tablaSolicitudes" class="table table-condensed table-bordered table-striped"><tbody ng-repeat="solicitud in $data"><tr><td data-title="\'Nombre\'" style="cursor:pointer;">{{solicitud.nombre}}</td><td data-title="\'Operaci&oacute;n\'">{{solicitud.operacion}}</td><td data-title="\'Descripci&oacute;n\'">{{solicitud.descripcion}}</td><td data-title="\'Representante\'">{{solicitud.solicitante}}</td><td data-title="\'Acci&oacute;n\'" class="text-center"><button type="button" class="btn btn-success btn-xs" ng-click="vm.aprobarSolicitud(solicitud.solicitudCambioId, $index)"><i class="fa fa-check"></i></button> <button type="button" class="btn btn-danger btn-xs" ng-click="vm.rechazarSolicitud(solicitud.solicitudCambioId, $index)"><i class="fa fa-remove"></i></button></td></tr></tbody></table></div></div></div></div></div>');
}]);
})();
