(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/common/simpleFooter-tpl.tpl.html',
    '<div class="pull-right hidden-xs"></div><strong>{{getDespachoActual().DESPACHO_NOMBRE}} - Revisión: SVN-{{appVersion}}</strong>');
}]);
})();
