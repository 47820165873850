(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/common/solicitud.enviar.tpl.html',
    '<div class="box-header with-border"><h3 class="box-title">CREAR SOLICITUD</h3></div><div class="box-body"><div class="row"><div class="col-sm-12"><label class="control-label">DESCRIPCION</label> <input class="form-control" name="motivoCancelacion" ng-model="dialogVm.solicitud.descripcion" rows="2" cols="50" required="" text-only=""></div></div></div><div class="box-footer text-right"><button type="button" class="btn btn-primary btn-sm" ng-click="dialogVm.solicitud.solicitarAutorizacion()"><i class="fa fa-paper-plane-o" aria-hidden="true"></i> ENVIAR</button> <button type="button" class="btn btn-danger btn-sm" ng-click="dialogVm.solicitud.cancelar()"><i class="fa fa-times-circle" aria-hidden="true"></i> CANCELAR</button></div>');
}]);
})();
