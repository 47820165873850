(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/citas/citas.detalleCancelada.tpl.html',
    '<div class="box-header with-border"><h3 class="box-title">VISITA CANCELADA</h3></div><div class="box-body"><div class="row"><div class="col-sm-12" ng-class="{ \'has-error\' : form.objetivo.$invalid && form.$submitted }"><label class="control-label">Motivo Cancelacion</label> <input class="form-control" name="motivoCancelacion" ng-model="citaCancelada.motivoCancelacion" rows="2" cols="50" disabled="" readonly=""></div></div></div><div class="box-footer text-right"><button type="button" class="btn btn-success btn-sm" ng-click="citaCancelada.aceptar()"><i class="fa fa-play-circle-o"></i> ACEPTAR</button></div>');
}]);
})();
