(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/common/simpleHeader-tpl.tpl.html',
    '<nav class="navbar navbar-static-top" role="navigation"><center><label class="logo-mini" style="color:white"><h2><b>SGT</b></h2></label><span class="logo-lg"><img src="img/LogoOJ-PII.png"></span></center></nav>');
}]);
})();
