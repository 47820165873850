(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/reportes/reportes.tpl.html',
    '<div class="slide" ui-view=""></div>');
}]);
})();
