(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/login/login.tpl.html',
    '<style>\n' +
    '  h2.textStyle {\n' +
    '    padding: 10px;\n' +
    '    text-align: center;\n' +
    '    border-image: 10px;\n' +
    '  }\n' +
    '/* Responsive Full Background Image Using CSS\n' +
    ' * Tutorial URL: http://sixrevisions.com/css/responsive-background-image/\n' +
    '*/\n' +
    'body {\n' +
    '  /* Location of the image */\n' +
    '  /*background-image: url(img/background-photo.jpg);*/\n' +
    '\n' +
    '  /* Image is centered vertically and horizontally at all times */\n' +
    '  background-position: center center;\n' +
    '\n' +
    '  /* Image doesn\'t repeat */\n' +
    '  background-repeat: no-repeat;\n' +
    '\n' +
    '  /* Makes the image fixed in the viewport so that it doesn\'t move when\n' +
    '     the content height is greater than the image height */\n' +
    '  background-attachment: fixed;\n' +
    '\n' +
    '  /* This is what makes the background image rescale based on its container\'s size */\n' +
    '  background-size: cover;\n' +
    '\n' +
    '  /* Pick a solid background color that will be displayed while the background image is loading */\n' +
    '  background-color:#6BB6D6;\n' +
    '\n' +
    '    border-top-width: -1px;\n' +
    '    border-top-style: solid;\n' +
    '  /* SHORTHAND CSS NOTATION\n' +
    '   * background: url(background-photo.jpg) center center cover no-repeat fixed;\n' +
    '   */\n' +
    '}\n' +
    '\n' +
    '/* For mobile devices */\n' +
    '@media only screen and (max-width: 767px) {\n' +
    '  body {\n' +
    '    /* The file size of this background image is 93% smaller\n' +
    '     * to improve page load speed on mobile internet connections */\n' +
    '    /*background-image: url(img/background-photo-mobile-devices.jpg);*/\n' +
    '  }\n' +
    '  }\n' +
    '</style><form name="form" class="login-form box-shadow animated" ng-class="{\'shake\': form.$submitted && response.err}" ng-submit="submitForm(form.$valid)" novalidate=""><div class="login-wrap"><div class="head"><img src="img/user.png" width="84" height="84" alt=""></div><h2 class="textStyle">Iniciar Sesión</h2><div class="input-group"><span class="input-group-addon"><i class="fa fa-user"></i></span> <input type="text" class="form-control" name="user" ng-model="loginData.username" placeholder="Usuario" autofocus="" required=""></div><div class="input-group"><span class="input-group-addon"><i class="fa fa-key"></i></span> <input type="password" class="form-control" name="password" ng-model="loginData.password" placeholder="Password" required=""></div><div class="text-center" ng-show="response.err"><p class="error">{{response.err}}</p></div><button class="btn btn-primary btn-block" type="submit" style="background-color:#013957!important;">Ingresar</button></div></form>');
}]);
})();
