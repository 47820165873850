(function(module) {
try {
  module = angular.module('templates-app');
} catch (e) {
  module = angular.module('templates-app', []);
}
module.run(['$templateCache', function($templateCache) {
  $templateCache.put('app/ciclos/ciclos.ver.tpl.html',
    '<div class="box box-primary"><form name="form" ng-submit="submitForm(form.$valid)" novalidate=""><div class="box-header with-border"><h2 class="box-title"><b>DETALLE CICLOS</b></h2></div><div class="box-body"><div class="row"><div class="col-sm-4" ng-class="{ \'has-error\' : form.anios.$invalid && form.$submitted }"><label class="control-label" for="anios">Año</label><ui-select name="anios" ng-model="filtro.anioId" theme="bootstrap" sortable="true" required=""><ui-select-match class="ui-select-match" placeholder="Seleccione...">{{$select.selected.anioId}}</ui-select-match><ui-select-choices class="ui-select-choices" repeat="item.anioId as item in anios | filter: $select.search"><div ng-bind-html="item.anioId | highlight: $select.search"></div></ui-select-choices></ui-select></div><div class="col-sm-2" style="margin-top:20px;"><button class="btn btn-primary" ng-click="generarReporte(filtro.anioId)">VER</button></div></div><br><div class="row"><div class="col-sm-12"><iframe ng-src="{{uriPDF}}" style="width:100%;min-height:650px;border:1px solid #666CCC" title="PDF in an i-Frame"></iframe></div></div></div></form></div>');
}]);
})();
